var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"p-5 mt-10 mb-5 bg-white rounded-lg"},[_c('h2',{staticClass:"mb-3 text-sm font-medium md:mb-10 md:text-2xl"},[_vm._v(" 電子發票設定 ")]),_vm._l((_vm.invoiceTypes),function(type,key){return _c('div',{key:type.value,staticClass:"mb-2"},[_c('el-radio',{staticClass:"block mb-2",attrs:{"label":type.value},on:{"change":_vm.onChnageRadio},model:{value:(_vm.form.invoice.carrier_type),callback:function ($$v) {_vm.$set(_vm.form.invoice, "carrier_type", $$v)},expression:"form.invoice.carrier_type"}},[_vm._v(_vm._s(type.label))]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
                key !== 'love_code' &&
                _vm.form.invoice.carrier_type === type.value &&
                type.value !== 3
            ),expression:"\n                key !== 'love_code' &&\n                form.invoice.carrier_type === type.value &&\n                type.value !== 3\n            "}]},[_c('el-input',{staticClass:"mr-2",attrs:{"name":key,"placeholder":type.placeholder},on:{"blur":_vm.onBlur},model:{value:(_vm.form.invoice[key]),callback:function ($$v) {_vm.$set(_vm.form.invoice, key, $$v)},expression:"form.invoice[key]"}}),_c('button',{staticClass:"rounded border border-orange-600 px-3 py-0.5 text-sm text-orange-600 disabled:cursor-not-allowed disabled:bg-gray-300",on:{"click":function($event){$event.preventDefault();return _vm.updateSetting(_vm.route.params.id, _vm.form.invoice)}}},[_vm._v("   確認 ")])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
                _vm.form.invoice.carrier_type === type.value && type.value === 3
            ),expression:"\n                form.invoice.carrier_type === type.value && type.value === 3\n            "}]},[_c('el-select',{staticClass:"mr-2",attrs:{"placeholder":type.placeholder},model:{value:(_vm.form.invoice[key]),callback:function ($$v) {_vm.$set(_vm.form.invoice, key, $$v)},expression:"form.invoice[key]"}},_vm._l((_vm.love_codes),function(item){return _c('el-option',{key:item.code,attrs:{"label":item.name,"value":item.code}})}),1),_c('button',{staticClass:"rounded border border-orange-600 px-3 py-0.5 text-sm text-orange-600 disabled:cursor-not-allowed disabled:bg-gray-300",on:{"click":function($event){$event.preventDefault();return _vm.updateSetting(_vm.route.params.id, _vm.form.invoice)}}},[_vm._v("   確認 ")])],1),(_vm.errorMessage && _vm.form.invoice.carrier_type === type.value)?_c('div',{staticClass:"text-red-500"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e()],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }