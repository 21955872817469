<template>
    <div class="p-5 bg-white rounded-lg shadow-lg">
        <h5 class="mb-5 text-lg md:text-2xl md:mb-10">推廣人撥款帳戶</h5>
        <el-form ref="formRefDom" :model="form" :rules="rules">
            <div class="grid grid-cols-2 md:gap-4">
                <el-form-item
                    class="w-full col-span-2 mb-5 md:col-span-1"
                    prop="bankCode"
                >
                    <label class="mb-0 leading-none text-gray-400">
                        銀行代碼
                        <el-input
                            v-model="form.bankCode"
                            class="w-full"
                        ></el-input>
                    </label>
                </el-form-item>
                <el-form-item
                    class="w-full col-span-2 mb-5 md:col-span-1"
                    prop="swift"
                >
                    <label class="mb-0 leading-none text-gray-400">
                        通匯代碼
                        <el-input
                            v-model="form.swift"
                            class="w-full"
                        ></el-input>
                    </label>
                </el-form-item>
            </div>
            <div class="grid grid-cols-2 md:gap-4">
                <el-form-item
                    class="w-full col-span-2 mb-5 md:col-span-1"
                    prop="accountName"
                >
                    <label class="mb-0 leading-none text-gray-400">
                        銀行戶名
                        <el-input
                            v-model="form.accountName"
                            class="w-full"
                        ></el-input>
                    </label>
                </el-form-item>
                <el-form-item
                    class="w-full col-span-2 mb-5 md:col-span-1"
                    prop="accountId"
                >
                    <label class="mb-0 leading-none text-gray-400">
                        銀行帳號
                        <el-input
                            v-model="form.accountId"
                            class="w-full"
                        ></el-input>
                    </label>
                </el-form-item>
            </div>
        </el-form>
        <BankPhotoUpload
            ref="bankPhotoUploadRefDom"
            class="mt-10"
            :isProvider="data.user.role === 0 ? false : true"
            :salesmanBankAccountPhoto.sync="imageFile"
        />
        <div class="flex justify-center mt-5">
            <button
                v-loading="loading"
                v-permission="['update']"
                :disabled="loading"
                class="px-3 py-2 w-[150px] text-white border border-red-500 bg-red-500 rounded-lg disabled:bg-gray-300 disabled:text-white disabled:cursor-not-allowed disabled:border-gray-300"
                @click="onSubmit()"
            >
                資料更新
            </button>
        </div>
    </div>
</template>

<script >
import { ref, reactive, computed, onActivated, onMounted, watch, getCurrentInstance, defineExpose, onBeforeUnmount } from "@vue/composition-api";
import { useRoute, useRouter } from "vue2-helpers/vue-router";
// 存褶影本上傳組件
import BankPhotoUpload from "@/components/BankAccountUpload.vue";
import store from "@/store";
const __sfc_main = {
  name: "SalesmanSalary"
};
__sfc_main.setup = (__props, __ctx) => {
  const router = useRouter();
  const route = useRoute();
  // 取得全域 this 方法 ex: this.$api, this.$message
  const {
    proxy
  } = getCurrentInstance();
  const providerData = computed(() => store.state.providerStore.providerData);
  const memberData = computed(() => store.state.memberStore.memberData);
  const loading = ref(false);
  // 存摺上傳 dom
  const bankPhotoUploadRefDom = ref(null);
  // 表單 dom
  const formRefDom = ref(null);
  // 表單資料
  const form = ref({
    bankCode: "822"
  });
  // 存摺圖
  const imageFile = ref(null);

  // 城市推廣人資料
  const data = ref({
    user: {}
  });
  const rules = ref({
    bankCode: [{
      required: true,
      message: "銀行代碼為必填",
      trigger: "blur"
    }],
    swift: [{
      required: true,
      message: "通匯代碼為必填",
      trigger: "blur"
    }],
    accountName: [{
      required: true,
      message: "帳戶名稱為必填",
      trigger: "blur"
    }],
    accountId: [{
      required: true,
      message: "銀行帳號為必填",
      trigger: "blur"
    }]
  });

  /**
   * 表單發送
   */
  async function onSubmit() {
    try {
      // 等待表單驗證是否成功 try catch 會自動判斷是 true 或 false 因次不用寫 if 判斷
      await formRefDom.value.validate();
      // 驗證是否有上傳存摺圖
      if (await bankPhotoUploadRefDom.value.validate()) {
        // 取得圖片檔案
        await bankPhotoUploadRefDom.value.uploadSalesmanBankAccountPhoto();
        await update();
      }
    } catch (err) {
      proxy.$message({
        type: "error",
        message: "表單驗證失敗"
      });
    }
  }

  /**
   * 新增或更新撥款帳戶資料
   */
  async function update() {
    loading.value = true;
    const result = new FormData();
    for (let key in form.value) {
      result.append(key, form.value[key]);
    }
    if (imageFile.value !== null) {
      result.append("image", imageFile.value);
    }
    try {
      await proxy.$api.UpdateCityPlanSalesmanBankAccountApi(route.params.id, result);
      await getData();
      proxy.$message({
        type: "success",
        message: "更新成功"
      });
    } catch (err) {
      proxy.$message({
        type: "error",
        message: "更新失敗"
      });
    } finally {
      loading.value = false;
    }
  }

  /**
   * 取得使用者資料
   */
  async function getData() {
    loading.value = true;
    try {
      // 取得使用者資料
      const {
        data: salesman
      } = await proxy.$api.GetApplyCityPlanSalesmanApi(route.params.id);
      data.value = salesman;
      return;
    } catch (err) {} finally {
      form.value = {};
      loading.value = false;
      setDefault();
    }
  }
  onMounted(async () => {
    await getData();
    if (data.value.banking !== undefined) {
      setDefault();
    }
  });
  function setDefault() {
    if (data.value.banking !== undefined) {
      form.value = {
        id: data.value.id,
        swift: data.value.banking.swift,
        bankCode: data.value.banking.bankCode,
        accountName: data.value.banking.accountName,
        accountId: data.value.banking.accountId
      };
    }
    bankPhotoUploadRefDom.value.getSalesmanPassbookPhoto(route.params.id);
  }
  return Object.assign({
    loading,
    bankPhotoUploadRefDom,
    formRefDom,
    form,
    imageFile,
    data,
    rules,
    onSubmit
  }, {
    setDefault
  });
};
__sfc_main.components = Object.assign({
  BankPhotoUpload
}, __sfc_main.components);
export default __sfc_main;
</script>


