<template>
    <div>
        <LoadingComponent :isLoading="loading" />
        <div class="mb-2 bg-white rounded-lg shadow-lg evaluation-area">
            <div class="total">
                <span class="title">總評分</span> <br />
                <span class="score">{{ total.average }}</span>
                <i class="fas fa-star"></i>
            </div>
            <div class="comment">
                <span class="title">評論數</span> <br />
                <span class="score">{{ total.rating_count }}</span>
                <span> 則</span>
            </div>
            <div v-if="total.score_count"
                 class="detail">
                <div class="comment-star-total">5 <i class="fas fa-star"></i> {{ total.score_count[5] ? total.score_count[5] : 0 }} 則</div>
                <div class="comment-star-total">4 <i class="fas fa-star"></i> {{ total.score_count[4] ? total.score_count[4] : 0 }} 則</div>
                <div class="comment-star-total">3 <i class="fas fa-star"></i> {{ total.score_count[3] ? total.score_count[3] : 0 }} 則</div>
                <div class="comment-star-total">2 <i class="fas fa-star"></i> {{ total.score_count[2] ? total.score_count[2] : 0 }} 則</div>
                <div class="comment-star-total">1 <i class="fas fa-star"></i> {{ total.score_count[1] ? total.score_count[1] : 0 }} 則</div>
            </div>
            <div v-else
                 class="detail">
                <div class="comment-star-total">5 <i class="fas fa-star"></i> 0 則</div>
                <div class="comment-star-total">4 <i class="fas fa-star"></i> 0 則</div>
                <div class="comment-star-total">3 <i class="fas fa-star"></i> 0 則</div>
                <div class="comment-star-total">2 <i class="fas fa-star"></i> 0 則</div>
                <div class="comment-star-total">1 <i class="fas fa-star"></i> 0 則</div>
            </div>
        </div>
        <div class="p-5 mb-3 bg-white rounded-lg shadow-lg">
            <div class="flex items-center">
                <div class="flex items-end">
                    <div class="flex items-center">
                        <div class="flex-1 mr-5">
                            <label class="text-gray-400">
                                訂單編號
                                <el-input v-model="filterOpts.order_id"
                                          size="small"
                                          clearable> </el-input>
                            </label>
                        </div>
                        <div class="flex-1 mr-5">
                            <label class="block text-gray-400"> 結束日期 範圍 </label>
                            <el-date-picker v-model="dateInputs"
                                            size="small"
                                            type="daterange"
                                            value-format="yyyy-MM-dd"
                                            range-separator="至"
                                            start-placeholder="開始日期"
                                            end-placeholder="结束日期">
                            </el-date-picker>
                        </div>
                        <div class="flex-1 mr-5">
                            <label class="text-gray-400">
                                活動項目
                                <el-select v-model="filterOpts.category_id"
                                           size="small"
                                           class="w-full"
                                           clearable>
                                    <el-option v-for="(category, index) in categoryOptions"
                                               :key="index"
                                               :label="category.name"
                                               :value="category.id">
                                        {{ category.name }}
                                    </el-option>
                                </el-select>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex mt-2">
                <button class="orange-btn-800 md:text-sm text-xs mr-1 w-[73px] h-[30px]"
                        @click="goSearch">搜尋</button>
                <button class="black-btn-outline md:text-sm text-xs w-[73px] h-[30px]"
                        @click="reset">重置</button>
                <button class="ml-2 px-5 min-w-[70px] h-[30px] max-w-[200px] border border-red-500 text-red-500 hover:bg-red-500 hover:text-white duration-500 rounded-lg"
                        @click.prevent="$router.push({ name: 'provider_comment_add', params: { userId: $route.params.id } })">
                    新增社群回饋
                </button>
            </div>
        </div>
        <div class="overflow-x-auto bg-white">
            <table class="myTable">
                <TableHead ref="TableHead"
                           :labels="tableLabels"
                           @changeSort="changeSort" />
                <tbody>
                    <tr v-for="(item, index) in datas"
                        :key="index"
                        class="text-center transition duration-200">
                        <td>{{ item.dating === null ? "無訂單號" : item.dating.order_id }}</td>
                        <td>{{ item.reviewer_name }}</td>
                        <td>
                            {{ item.dating === null ? "無分類" : item.dating.category.name }}
                        </td>
                        <td>
                            {{ item.reviewed_at | formatDateTime }}
                        </td>
                        <td :key="item.id"
                            class="table-score">
                            <i v-for="(i, index) in item.score"
                               :key="'star' + index"
                               class="fas fa-star"></i>
                        </td>
                        <td>{{ item.status === 0 ? "啟用" : "關閉" }}</td>
                        <td class="edit">
                            <span class="cursor-pointer"
                                  @click="
                                    $router.push({
                                        name: 'provider_comment',
                                        params: { userId: $route.params.id, id: item.id },
                                    })
                                "><i class="far fa-edit"></i></span>
                        </td>
                        <td>
                            <button class="cursor-pointer disabled:text-gray-300 disabled:cursor-not-allowed"
                                    :disabled="item.dating !== null"
                                    @click="openDialog(item.id)">
                                <span><i class="fas fa-trash-alt"></i></span>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="flex justify-center mt-3 mb-3">
                <MyPagination :paginationData="paginationData"
                              :currentPage="currentPage"
                              @onPageSizeChange="pageSizeChange"
                              @onCurrentPageChange="currentPageChange" />
            </div>
        </div>
        <!-- 確認是否取消預訂彈窗 -->
        <my-dialog :showDialog="showDialog"
                   :customClass="'top-1/4 z-[70]'"
                   :customBlackBgClass="'z-[60]'"
                   @onCloseDialog="closeDialog">
            <div class="w-full p-5 text-sm bg-white rounded-lg md:p-10">
                <p>確認解除此評論嗎？</p>
                <div class="flex justify-center mt-5">
                    <button :disabled="loading"
                            class="border border-black px-3 py-0.5 rounded mr-5 disabled:bg-gray-300 disabled:cursor-not-allowed disabled:text-white disabled:border-gray-300"
                            @click.prevent="closeDialog">
                        關閉
                    </button>
                    <button v-loading="loading"
                            :disabled="loading"
                            class="bg-orange-600 px-3 py-0.5 rounded text-white disabled:bg-gray-300 disabled:cursor-not-allowed"
                            @click.prevent="deleteComment(commentId)">
                        確認
                    </button>
                </div>
            </div>
        </my-dialog>
    </div>
</template>

<script>
// 分頁組件
import MyPagination from "@/components/MyPagination.vue";
// 旋轉動畫組件
import LoadingComponent from "@/components/Loading.vue";
// table Head
import TableHead from "@/components/table/TableHead.vue";
// 導入自定義彈窗組件
import MyDialog from "@/components/Dialog.vue";
// init search Filter options
const defaultFilterOpts = {
    order_id: "",
    category_id: "",
    ended_at_start: "",
    ended_at_end: "",
    sort_by: "", // created_at or started_at or gross_price
    sort_type: "", // asc or desc
};
export default {
    name: "EvaluationList",
    components: {
        LoadingComponent,
        MyPagination,
        TableHead,
        MyDialog,
    },
    props: {
        // 是否為服務商
        isProvider: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        // table 標籤
        tableLabels() {
            const tableLabels = [
                {
                    label: "訂單編號",
                },
                {
                    label: this.isProvider ? "會員暱稱" : "服務商暱稱",
                },
                {
                    label: "活動項目",
                },
                {
                    label: "評論時間",
                    isSort: true,
                    val: "reviewed_at",
                },
                {
                    label: "評分",
                    isSort: true,
                    val: "score",
                },
                {
                    label: "狀態",
                },
                {
                    label: "編輯",
                },
                {
                    label: "刪除",
                },
            ];
            return tableLabels;
        },
        // 統整 getlist api filters
        filterData() {
            const filter = {};
            for (const key in this.filterOpts) {
                if (this.filterOpts[key]) {
                    filter[key] = this.filterOpts[key];
                }
            }
            return {
                ...filter,
                role: this.isProvider ? 1 : 0,
                rated_only: 1,
                limit: this.paginationData.limit,
                page: this.currentPage,
            };
        },
    },
    data() {
        return {
            loading: false,
            datas: [],
            id: "", // 服務商id
            paginationData: {
                limit: 10,
                total: 0,
            },
            currentPage: 1,
            filterOpts: { ...defaultFilterOpts },
            dateInputs: [], // 時間範圍
            total: {
                average: 0,
                rating_count: 0,
                score_count: {},
            },
            // 評論 id
            commentId: null,
            // 判斷是否開啟彈窗
            showDialog: false,
            // 分類選項
            categoryOptions: [],
        };
    },
    methods: {
        // 預設
        setDefault(val) {
            this.id = val.id;
            this.getList();
            this.rating();
        },
        // // 讀取 list api
        // async getList() {
        //     this.loading = true;
        //     const { status, data } = await this.$api
        //         .SearchDatingByUser(this.id, this.filterData)
        //         .then((res) => {
        //             return { data: res.data, status: res.status };
        //         })
        //         .catch(() => {
        //             this.$message({
        //                 type: "error",
        //                 message: "取得資料失敗",
        //             });
        //         })
        //         .finally(() => {
        //             this.loading = false;
        //         });
        //     if (status === 200) {
        //         this.datas = [...data.data];
        //         this.paginationData = {
        //             limit: data.per_page,
        //             total: data.total,
        //         };
        //     }
        // },

        async getList() {
            this.loading = true;
            try {
                const { data } = await this.$api.GetCommentListApi(
                    this.$route.params.id,
                    this.filterData
                );
                this.datas = data.data.map((item) => {
                    return { ...item, user: { name: item.reviewer_real_name } };
                });
                this.datas = data.data;
                this.paginationData = {
                    limit: data.per_page,
                    total: data.total,
                };
            } catch (err) {
                this.$message({
                    type: "error",
                    message: "取得資料失敗",
                });
            } finally {
                this.loading = false;
            }
        },
        // 評分統計 API
        rating() {
            const params = { role: this.isProvider ? 1 : 0 };
            this.$api.GetSummaryRatting(this.id, params).then((res) => {
                this.total = { ...res.data };
            });
        },
        // 搜尋
        goSearch() {
            this.currentPage = 1;
            this.getList();
        },
        // 改變排序
        changeSort(val) {
            this.filterOpts.sort_by = val.sortBy;
            this.filterOpts.sort_type = val.orderBy;
            this.getList();
        },
        // 重置
        reset() {
            this.dateInputs = [];
            this.filterOpts = { ...defaultFilterOpts };
            this.$refs.TableHead.reset();
            this.currentPage = 1;
            this.paginationData = {
                limit: 10,
                total: 0,
            };
            this.getList();
        },
        // 切頁相關
        pageSizeChange(val) {
            // 更新每頁取得幾筆資料參數
            this.paginationData.limit = val;
            // 當前頁面設定為第一頁
            this.currentPage = 1;
            this.getList();
        },
        currentPageChange(val) {
            // 設定目前分頁為第幾面
            this.currentPage = val;
            this.getList();
        },
        showComment(val) {
            this.$alert(
                `<span style="word-wrap:break-word">${val}</span>`,
                "",
                {
                    closeOnClickModal: true,
                    showConfirmButton: false,
                    dangerouslyUseHTMLString: true,
                }
            );
        },
        /**
         * 開啟刪除評論彈窗
         * @param { type String(字串) } commentId 評論 id
         */
        openDialog(commentId) {
            this.showDialog = true;
            this.commentId = commentId;
        },
        /**
         * 關閉刪除評論彈窗
         */
        closeDialog() {
            this.showDialog = false;
            this.commentId = null;
        },
        /**
         * 取得分類列表a api
         */
        async getCategoriesList() {
            try {
                const { data } = await this.$api.GetCategoriesListApi();
                this.categoryOptions = data.categories;
            } catch (err) {
                this.$message({
                    type: "error",
                    message: "取得分類列表失敗",
                });
            }
        },
        /**
         * 刪除評論
         * @param { type String or Number(字串或數字) } id 評論 id
         */
        async deleteComment(id) {
            this.loading = true;
            try {
                await this.$api.DeleteCommentApi(id);
                this.$message({
                    type: "success",
                    message: "刪除資料成功",
                });
                this.closeDialog();
                this.getList();
            } catch (err) {
                console.log(err);
                this.$message({
                    type: "error",
                    message: "刪除評論失敗",
                });
            } finally {
                this.loading = false;
            }
        },
    },
    watch: {
        dateInputs(val) {
            if (val === null || val.length === 0) {
                this.filterOpts.reviewed_at_start = "";
                this.filterOpts.reviewed_at_end = "";
                return;
            }
            this.filterOpts.reviewed_at_start = val[0];
            this.filterOpts.reviewed_at_end = val[1];
        },
    },
    mounted() {
        this.getCategoriesList();
    },
    activated() {
        if (this.id) {
            this.getList();
        }
    },
};
</script>

<style lang="scss" scoped>
label {
    color: #757575;
    font-size: 16px;
    font-weight: 400;
    line-height: 34px;
}

.evaluation-area {
    display: flex;
    padding: 20px;
    .total {
        text-align: center;
        border-right: solid 1px #ccc;
        flex: 1;
    }
    .comment {
        flex: 1;
        text-align: center;
        border-right: solid 1px #ccc;
    }
    .detail {
        flex: 4;
        display: flex;
        justify-content: center;
        align-items: center;
        .comment-star-total {
            flex: 1;
            text-align: center;
            font-weight: 500;
            font-size: 16px;
            .fa-star {
                font-size: 12px;
                margin-left: 0px;
                margin-bottom: 2px;
                color: #ff5733 !important;
            }
        }
    }
    .score {
        font-size: 30px;
        font-weight: 500;
    }
    .fa-star {
        margin-left: 5px;
        color: #ff5733 !important;
    }
}

.edit {
    text-decoration-line: underline;
    cursor: pointer;
}

.table-score {
    text-align: left;
    padding-left: 30px;
    .fa-star {
        color: #ff5733;
    }
}

::v-deep .el-input__inner {
    max-width: 100%;
}

::v-deep .el-range-separator {
    width: 20px;
}

::v-deep .el-message-box__message {
    word-wrap: break-word !important;
}
</style>
