<template>
    <div>
        <LoadingComponent :isLoading="loading" />
        <div class="shadow-lg rounded-lg bg-white flex items-center p-5 mb-3">
            <div class="grid grid-cols-12 gap-4">
                <div class="col-span-3">
                    <label class="text-gray-400">
                        金流編號
                        <el-input v-model="filterOpts.transaction_id" size="small" clearable> </el-input>
                    </label>
                </div>
                <div class="col-span-4">
                    <label class="text-gray-400">
                        交易日期 範圍
                        <el-date-picker
                            v-model="dateInputs"
                            size="small"
                            type="daterange"
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="開始日期"
                            end-placeholder="结束日期">
                        </el-date-picker>
                    </label>
                </div>
                <div class="col-span-3">
                    <label class="text-gray-400">
                        事件
                        <el-select v-model="filterOpts.type" size="small" class="w-full" clearable>
                            <el-option v-for="(type, index) in payStatusOptions" :key="index" :label="type.label" :value="type.value">
                                {{ type.label }}
                            </el-option>
                        </el-select>
                    </label>
                </div>

                <div class="col-span-2 flex items-end">
                    <button class="orange-btn-800 md:text-sm text-xs mr-1 w-[73px] h-[30px] flex-1" @click="goSearch">搜尋</button>
                    <button class="black-btn-outline md:text-sm text-xs w-[73px] h-[30px] flex-1" @click="reset">重置</button>
                </div>
            </div>
        </div>
        <div class="flex area-pay mb-2">
            <div class="pay-balance flex-2 shadow-lg rounded-lg">帳戶餘額 : $ {{ balance | formatCurrency }}</div>
            <button
                v-permission="['update']"
                class="flex-1 orange-btn-800 md:text-sm text-xs mx-2 w-[73px] h-[43px] flex-1"
                @click="showPopUp('add')">
                新增餘額
            </button>
            <button v-permission="['update']" class="flex-1 black-btn-black w-[73px] h-[43px]" @click="showPopUp('remove')">扣除餘額</button>
        </div>
        <div class="overflow-x-auto bg-white">
            <table class="myTable">
                <thead>
                    <tr>
                        <th>金流編號</th>
                        <th>事件</th>
                        <th>金額</th>
                        <th>餘額</th>
                        <th>交易時間</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in datas" :key="index" class="text-center duration-200 transition">
                        <td>{{ item.id }}</td>
                        <td>
                            {{ item.type | transactionType }}
                            <span v-if="item.details.datingOrderId || item.details.feedbackId || item.details.demandDemandId">
                                (
                                <span v-if="item.type === 'PREPARE_DATING' || item.type === 'CANCEL_PREPARED_DATING'" class="memo-not">
                                    {{ item.details.datingOrderId }}
                                </span>
                                <span
                                    v-else-if="item.type === 'DEDUCTION' || item.type === 'REFUND' || item.type === 'CASH'"
                                    class="memo text-red-500"
                                    @click="goCustomer(item.details.feedbackId)">
                                    {{ item.details.feedbackId }}
                                </span>
                                <span
                                    v-else-if="item.type === 'CREATE_DATING_DEMAND' || item.type === 'REFUND_DATING_DEMAND'"
                                    class="memo text-red-500"
                                    @click="goDemandId(item.details.demandId)">
                                    {{ item.details.demandDemandId }}
                                </span>
                                <span v-else class="memo text-red-500" @click="goEdit(item.details.datingId)">
                                    {{ item.details.datingOrderId }}
                                </span>

                                )
                            </span>
                        </td>
                        <td>
                            <span v-if="item.amount >= 0">+</span>
                            {{ item.amount | formatCurrency }}
                        </td>
                        <td>${{ item.balance | formatCurrency }}</td>
                        <td>{{ item.created_at | formatDateTime }}</td>
                    </tr>
                </tbody>
            </table>
            <div class="justify-center mt-3 flex mb-3">
                <MyPagination
                    :paginationData="paginationData"
                    :currentPage="currentPage"
                    @onPageSizeChange="pageSizeChange"
                    @onCurrentPageChange="currentPageChange" />
            </div>
        </div>
        <PopupUpdateBalance
            v-model="showDialogPopup"
            :isProvider="isProvider"
            :currentBalance="balance"
            :param="popUpInfo"
            @confirm="UpdateUserBalance" />
    </div>
</template>

<script>
// 分頁組件
import MyPagination from "@/components/MyPagination.vue";
// 旋轉動畫組件
import LoadingComponent from "@/components/Loading.vue";
// 新增扣除餘額彈窗
import PopupUpdateBalance from "./PopupUpdateBalance.vue";
import orderConfig from "@/config/orderConfig";
// init search Filter options
const defaultFilterOpts = {
    transaction_id: "",
    type: "",
    created_at_start: "",
    created_at_end: "",
};
export default {
    name: "PaymentFlowList",
    components: {
        LoadingComponent,
        MyPagination,
        PopupUpdateBalance,
    },
    props: {
        // 當前 user 是否為服務商
        isProvider: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        // 統整 getlist api filters
        filterData() {
            const filter = {};
            // 判斷是否為空值，移除不必要key
            for (const key in this.filterOpts) {
                if (this.filterOpts[key]) {
                    filter[key] = this.filterOpts[key];
                }
            }
            // type 欄位設置為 array
            if (filter.type === "CANCEL_PREPARED_DATING") {
                filter.type = ["CANCEL_PREPARED_DATING", "CANCEL_PREPARED_DATING_EXTENSION"];
            } else if (filter.type) {
                filter.type = [filter.type];
            }

            return {
                ...filter,
                limit: this.paginationData.limit,
                page: this.currentPage,
            };
        },
        // 付款狀態 options
        payStatusOptions() {
            const config = this.isProvider ? orderConfig.transactionProviderType : orderConfig.transactionUserType;
            const result = [
                {
                    label: "全部",
                    value: "",
                },
            ];
            for (const key in config) {
                result.push({
                    label: config[key],
                    value: key,
                });
            }
            return result;
        },
    },
    data() {
        return {
            loading: false,
            datas: [],
            id: "",
            balance: 0,
            paginationData: {
                limit: 10,
                total: 0,
            },
            currentPage: 1,
            filterOpts: { ...defaultFilterOpts },
            dateInputs: [], // 時間範圍
            showDialogPopup: false,
            popUpInfo: {
                title: "新增餘額",
                message: "新增本用戶的餘額",
                type: "add",
            },
        };
    },
    methods: {
        // 預設
        setDefault(val) {
            this.id = val.id;
            this.getList();
            this.getBalance();
        },
        // 讀取 list api
        async getList() {
            this.loading = true;
            const { status, data } = await this.$api
                .SearchTransactions(this.id, this.filterData)
                .then((res) => {
                    return { data: res.data, status: res.status };
                })
                .catch(() => {
                    this.$message({
                        type: "error",
                        message: "取得資料失敗",
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
            if (status === 200) {
                this.datas = [...data.data];
                this.paginationData = {
                    limit: data.per_page,
                    total: data.total,
                };
            }
        },
        // 取餘額
        getBalance() {
            this.$api
                .GetUserBalance(this.id)
                .then((res) => {
                    this.balance = res.data.balance;
                })
                .catch(() => {
                    this.$message({
                        type: "error",
                        message: "取得餘額失敗",
                    });
                });
        },
        // 搜尋
        goSearch() {
            this.currentPage = 1;
            this.getList();
        },
        // 重置
        reset() {
            this.dateInputs = [];
            this.filterOpts = { ...defaultFilterOpts };
            this.currentPage = 1;
            this.paginationData = {
                limit: 10,
                total: 0,
            };
            this.getList();
        },
        // 切頁相關
        pageSizeChange(val) {
            // 更新每頁取得幾筆資料參數
            this.paginationData.limit = val;
            // 當前頁面設定為第一頁
            this.currentPage = 1;
            this.getList();
        },
        currentPageChange(val) {
            // 設定目前分頁為第幾面
            this.currentPage = val;
            this.getList();
        },
        // 到訂單細節頁,將此頁資訊帶入
        goEdit(id) {
            const userID = this.$route.params.id;
            const tab = this.isProvider ? "provider_update" : "member_update";
            const pre = "PaymentFlowList";
            // this.$router.push({
            //     name: "order_detail",
            //     params: { tab, pre, id, userID }
            // });
            window.open(`/#/${userID}/${pre}/${tab}/order_detail/${id}`);
        },
        goCustomer(id) {
            // this.$router.push({
            //     name: "customer-complaint-detail",
            //     params: {
            //         customerID: id
            //     }
            // });
            // const idx = parseInt(id);
            // this.$router.push({
            //     name: "customer-complaint-detail",
            //     customerID: idx
            // });
            window.open(`/#/customer_complaint/customer/${id}`);
        },
        goDemandId(id) {
            window.open(`/#/order_manage/rightnow_activity/rightnow_activity_detail/${id}`);
        },
        // 新增扣除餘額
        UpdateUserBalance(val) {
            const { amount, orderId, exchange } = val;
            const params = {
                feedback_id: orderId,
                amount,
                exchange,
            };

            // 檢查餘額
            if (this.balance + amount < 0) {
                this.$message({
                    type: "error",
                    message: "餘額不足",
                });
                return;
            } else if (amount > 50000) {
                this.$message({
                    type: "error",
                    message: "單筆最多新增 $50,000",
                });
                return;
            }

            this.$api
                .UpdateUserBalance(this.id, params)
                .then((res) => {
                    console.log(res);
                    this.$message({
                        type: "success",
                        message: "更新成功",
                    });
                    this.getList();
                    this.getBalance();
                })
                .catch((err) => {
                    let msg = "無此客訴單號";
                    if (err.response.data.error?.message === "Insufficient balance") {
                        msg = "餘額不足";
                    }
                    this.$message({
                        type: "error",
                        message: msg,
                    });
                });
        },
        // 顯示 PopUp
        showPopUp(type) {
            if (type == "add") {
                this.popUpInfo = {
                    title: "新增餘額",
                    message: "新增本用戶的餘額",
                    type,
                };
            } else {
                this.popUpInfo = {
                    title: "扣除餘額",
                    message: "扣除本用戶的餘額",
                    type,
                };
            }
            this.showDialogPopup = true;
        },
    },
    watch: {
        dateInputs(val) {
            if (val === null || val.length === 0) {
                this.filterOpts.created_at_start = "";
                this.filterOpts.created_at_end = "";
                return;
            }
            this.filterOpts.created_at_start = val[0];
            this.filterOpts.created_at_end = val[1];
        },
    },
    activated() {
        if (this.id) {
            this.getList();
            this.getBalance();
        }
    },
};
</script>

<style lang="scss" scoped>
label {
    font-size: 16px;
    font-weight: 400;
    line-height: 34px;
}

.area-pay {
    display: flex;
    height: 43px;
    .pay-balance {
        flex: 2;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.memo {
    font-size: 14px;
    text-decoration-line: underline;
    cursor: pointer;
}

.memo-not {
    font-size: 14px;
}

::v-deep .el-input__inner {
    max-width: 100%;
}

::v-deep .el-range-separator {
    width: 20px;
}
</style>
