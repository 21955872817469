<script>
import {
    ref,
    reactive,
    del,
    set,
    computed,
    onActivated,
    onMounted,
    watch,
    getCurrentInstance,
    defineExpose,
    onBeforeUnmount,
    nextTick,
} from "@vue/composition-api";
// 旋轉動畫組件
import LoadingComponent from "@/components/Loading.vue";
// 分頁組件
import MyPagination from "@/components/MyPagination.vue";
// 訂單狀態
import orderConfig from "@/config/orderConfig.js";
// table Head
import TableHead from "@/components/table/TableHead.vue";
import moment from "moment";
import { isEmpty, formatCurrency } from "@/service/anyService";
import { useRoute, useRouter } from "vue2-helpers/vue-router";
export default {
    name: "UserRightNowActivityList",
    components: {
        LoadingComponent,
        MyPagination,
        TableHead,
    },
    props: {
        isProvider: {
            type: Boolean,
            default: true,
        },
    },
    emits: [],
    setup(props, { emit }) {
        const { proxy } = getCurrentInstance();
        const router = useRouter();
        const route = useRoute();
        const loading = ref(false);

        // 時間範圍
        const dateInputs = ref([]);

        // 搜尋條件表單資料
        const form = ref({});

        // 過濾條件
        const filterData = computed(() => {
            Object.keys(form.value).forEach((key) => {
                if (isEmpty(form.value[key])) {
                    del(form.value, key);
                }
            });
            return {
                ...form.value,
                role: props.isProvider ? 1 : 0,
                limit: paginationData.value.limit,
            };
        });

        // 訂單狀態 options
        const statusOptions = computed(() => {
            const status = orderConfig.flashOrderStatus;
            const result = [
                {
                    label: "全部",
                    value: "",
                },
            ];
            for (const key in status) {
                result.push({
                    label: status[key],
                    value: key,
                });
            }
            return result;
        });

        // 資料
        const datas = ref([]);

        // table header
        const tableLabels = ref([
            {
                label: "狀態",
            },
            {
                label: "報名狀態",
            },
            {
                label: "活動名稱",
            },
            {
                label: "總額",
                isSort: true,
                val: "gross_price",
            },
            {
                label: "付款類型",
            },
            {
                label: "成立時間",
                isSort: true,
                val: "created_at",
            },
            {
                label: "開始時間",
                isSort: true,
                val: "started_at",
            },
            {
                label: "查看",
            },
        ]);
        // 判斷是非服務商時 移除服務商報名狀態欄位
        if (!props.isProvider) {
            const findTableHeadIndex = tableLabels.value.findIndex(
                (data) => data.label === "報名狀態"
            );
            tableLabels.value.splice(findTableHeadIndex, 1);
        }

        // 分頁資料
        const paginationData = ref({
            limit: 10,
            total: 0,
        });

        // 當前頁數
        const currentPage = ref(1);

        /**
         * 透過父組件 呼叫執行
         * @param { type Object(物件) } userData 使用者資料
         */

        function setDefault(userData) {}

        // 切頁相關
        function pageSizeChange(val) {
            // 更新每頁取得幾筆資料參數
            paginationData.value.limit = val;
            // 當前頁面設定為第一頁
            currentPage.value = 1;
            getList(route.params.id, filterData.value, currentPage.value);
        }
        function currentPageChange(val) {
            // 設定目前分頁為第幾面
            currentPage.value = val;
            getList(route.params.id, filterData.value, currentPage.value);
        }
        /**
         * 改變排序
         * @param { type Object(物件) } sortData
         * @example {
         *   @param { type String(字串) } sortBy 要排序的  key
         *   @param { type String(字串) } orderBy 排序 正序 或 倒序  enum ["desc", "asc"]
         * }
         */
        function changeSort(sortData) {
            currentPage.value = 1;
            form.value.sort_by = sortData.sortBy;
            form.value.sort_type = sortData.orderBy;
            getList(route.params.id, filterData.value, currentPage.value);
        }
        /**
         * 取得資料
         * @param { type String(字串) } userID 使用者 id
         * @param { type Object(物件) } filter 過濾參數
         * @param { type String or Number(字串或數字) } page 頁數
         */
        async function getList(userID, filter, page) {
            loading.value = true;
            try {
                const { data } = await proxy.$api.getDemandListApi(userID, {
                    ...filter,
                    page,
                });
                datas.value = data.data;
                paginationData.value = {
                    limit: data.per_page,
                    total: data.total,
                };
            } catch (err) {
                console.log("SearchDemandsDatingsApi err =>", err);
            } finally {
                loading.value = false;
            }
        }

        /**
         * 搜尋資料
         * @param { type String(字串) } userID 使用者 id
         * @param { type Object(物件) } filter 過濾參數
         */
        async function search(userID, filter) {
            currentPage.value = 1;
            await getList(route.params.id, filterData.value, currentPage.value);
        }

        function reset() {
            form.value = {};
            currentPage.value = 1;
            paginationData.value = {
                limit: 10,
                total: 0,
            };
            dateInputs.value = [];
            if (this.$refs.tableHeadRefDom) {
                this.$refs.tableHeadRefDom.reset();
            }
            getList(route.params.id, filterData.value, currentPage.value);
        }
        /**
         * 跳轉訂單細節頁
         * @param { type String(字串) } id
         */
        function goEdit(id) {
            router.push({
                name: "rightnow_activity_detail",
                params: { id },
            });
        }

        watch(
            () => dateInputs.value,
            (val) => {
                if (val === null || val.length === 0) {
                    del(form.value, "started_at_start");
                    del(form.value, "started_at_end");
                    return;
                }
                form.value.started_at_start = val[0];
                form.value.started_at_end = val[1];
            }
        );

        onMounted(async () => {
            await getList(route.params.id, filterData.value, currentPage.value);
        });

        return {
            setDefault,
            currentPage,
            paginationData,
            pageSizeChange,
            currentPageChange,
            loading,
            form,
            statusOptions,
            filterData,
            tableLabels,
            changeSort,
            datas,
            goEdit,
            getList,
            search,
            reset,
            dateInputs,
            route,
        };
    },
    render() {
        const searchForm = (
            <div class="flex items-end p-5 mb-3 bg-white rounded-lg shadow-lg">
                <div class="mr-5 w-[150px]">
                    <label class="text-gray-400">
                        訂單編號
                        <el-input
                            vModel={this.form.demand_id}
                            size="small"
                            clearable
                        ></el-input>
                    </label>
                </div>
                <div class="flex items-end mr-5">
                    <div class="flex-1 block text-gray-400">
                        <label class="block">開始日期 範圍</label>
                        <el-date-picker
                            vModel={this.dateInputs}
                            size="small"
                            type="daterange"
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="開始日期"
                            class="max-w-[300px] "
                            end-placeholder="结束日期"
                        ></el-date-picker>
                    </div>
                </div>
                <div class="mr-5">
                    <label class="block text-gray-400">狀態</label>
                    <el-select
                        vModel={this.form.status}
                        size="small"
                        class="max-w-[150px]"
                        clearable
                    >
                        {this.statusOptions.map((option, index) => (
                            <el-option
                                key={index}
                                label={option.label}
                                value={option.value}
                            >
                                {option.label}
                            </el-option>
                        ))}
                    </el-select>
                </div>
                <div class="flex-1 text-right">
                    <button
                        class="orange-btn-800 md:text-sm text-xs mr-1 w-[73px] h-[30px] flex-1"
                        vOn:click_prevent={() =>
                            this.search(this.route.params.id, this.filterData)
                        }
                    >
                        搜尋
                    </button>
                    <button
                        class="black-btn-outline md:text-sm text-xs w-[73px] h-[30px] flex-1"
                        vOn:click_prevent={() => this.reset()}
                    >
                        重置
                    </button>
                </div>
            </div>
        );

        const tableBody = (
            <tbody>
                {this.datas.map((data, index) => {
                    return (
                        <tr
                            key={data.id}
                            class="text-center transition duration-200"
                        >
                            <td>
                                <div>
                                    {orderConfig.flashOrderStatus[data.status]}
                                </div>
                                {data.is_x ? (
                                    <div class="text-red-500">CityBananaX</div>
                                ) : null}
                                <div>來源：{data.source_application}</div>
                            </td>
                            {this.isProvider ? (
                                <td>
                                    {
                                        orderConfig.flashProviderSignUpStatus[
                                            data.enrollers[0].status
                                        ]
                                    }
                                </td>
                            ) : null}

                            <td>{data.name}</td>
                            <td>${formatCurrency(data.details.pointPaid)}</td>
                            <td>{data.paid_by === 1 ? "現金" : "線上"}</td>
                            <td>
                                {moment(data.created_at).format(
                                    "YY/MM/DD HH:mm"
                                )}
                            </td>
                            <td>
                                {isEmpty(data.started_at)
                                    ? "服務商到場"
                                    : moment(data.started_at).format(
                                          "YY/MM/DD HH:mm"
                                      )}
                            </td>
                            <td
                                vPermission={["update"]}
                                class="cursor-pointer"
                                vOn:click={() => this.goEdit(data.id)}
                            >
                                <i class="far fa-edit"></i>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        );

        return (
            <div>
                <LoadingComponent isLoading={this.loading} />
                {searchForm}
                <div class="py-5 bg-white">
                    <div class="overflow-x-auto bg-white">
                        <table class="myTable">
                            <TableHead
                                ref="tableHeadRefDom"
                                labels={this.tableLabels}
                                vOn:changeSort={this.changeSort}
                            />
                            {tableBody}
                        </table>
                    </div>
                    <MyPagination
                        class="mt-5"
                        paginationData={this.paginationData}
                        currentPage={this.currentPage}
                        vOn:onPageSizeChange={this.pageSizeChange}
                        vOn:onCurrentPageChange={this.currentPageChange}
                    />
                </div>
            </div>
        );
    },
};
</script>

<style lang="scss" scoped></style>
