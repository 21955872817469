<template>
    <div>
        <SearchForm
            ref="searchFormRefDom"
            @onReset="resetData"
            @onSearch="filterSearch"
        />
        <div class="mt-5 overflow-x-auto bg-white rounded-lg shadow-lg">
            <table class="myTable">
                <TableHead
                    ref="tableHeadRefDom"
                    :labels="tableLabels"
                    @changeSort="changeSort"
                />
                <tbody v-if="datas.length > 0">
                    <tr
                        v-for="(item, index) in datas"
                        :key="index"
                        class="text-center transition duration-200"
                    >
                        <td>
                            {{ $subString(item.name, 10) }}
                        </td>
                        <td>{{ item.phone }}</td>
                        <td>{{ item.created_at | formatDateTime }}</td>
                        <td>{{ UserPermissions[item.status] }}</td>
                        <td
                            v-permission="['update']"
                            class="cursor-pointer"
                            @click="goEdit(item.id, item.role)"
                        >
                            <i class="far fa-edit"></i>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="flex justify-center mt-3 mb-3">
                <MyPagination
                    :pagination-data="paginationData"
                    :current-page="currentPage"
                    @onPageSizeChange="pageSizeChange"
                    @onCurrentPageChange="currentPageChange"
                />
            </div>
        </div>
    </div>
</template>

<script >
import { ref, reactive, computed, onActivated, onMounted, watch, getCurrentInstance } from "@vue/composition-api";
import { useRoute, useRouter } from "vue2-helpers/vue-router";
// 搜尋條件
import SearchForm from "./DownlineUsersSearchForm.vue";
// table Head
import TableHead from "@/components/table/TableHead.vue";
// 分頁組件
import MyPagination from "@/components/MyPagination.vue";
// 判斷是否 empty 方法
import { isEmpty } from "@/service/anyService";
// 城市推廣人狀態
import { SalesmanPermissions } from "@/config/salesmanConfig";
// 會員狀態
import { UserPermissions } from "@/config/userConfig";
const __sfc_main = {};
__sfc_main.props = {
  // 判斷是否為服務商
  isProvider: {
    type: Boolean,
    default: false
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const router = useRouter();
  const route = useRoute();
  // 取得全域 this 方法 ex: this.$api, this.$message
  const {
    proxy
  } = getCurrentInstance();
  const props = __props;
  function setDefault() {}
  // table head dom
  const tableHeadRefDom = ref(null);
  // 預設搜尋條件
  const defaultFilterOpts = {};
  // 搜尋條件值
  const filterOpts = ref(defaultFilterOpts);
  // table head 標題
  const tableLabels = ref([{
    label: "暱稱"
  }, {
    label: "手機"
  }, {
    label: "加入時間"
  }, {
    label: "狀態"
  }, {
    label: "查看"
  }]);
  const loading = ref(false);
  // 搜尋表單 dom
  const searchFormRefDom = ref(null);
  // 分頁資料
  const paginationData = ref({
    limit: 10,
    total: 0
  });
  // 預設頁面
  const currentPage = ref(1);
  // 列表
  const datas = ref([{
    user: {
      name: ""
    },
    category: {
      name: ""
    }
  }]);

  /**
   * 更換每頁筆數
   * @param { type Number(數字) } val 每頁筆數
   */
  function pageSizeChange(val) {
    // 更新每頁取得幾筆資料參數
    paginationData.value.limit = val;
    // 當前頁面設定為第一頁
    currentPage.value = 1;
    getList();
  }

  /**
   * 分頁切換
   * @param { type Number(數字) } val 下一頁數字
   */
  function currentPageChange(val) {
    // 設定目前分頁為第幾面
    currentPage.value = val;
    getList();
  }

  /**
   * 取得列表資料
   */
  async function getList() {
    loading.value = true;
    try {
      const {
        data
      } = await proxy.$api.GetApplyCityPlanSalesmanDownLineUsersApi(route.params.id, setFilterData());
      currentPage.value = data.current_page;
      paginationData.value = {
        limit: data.per_page,
        total: data.total
      };
      datas.value = data.data;
    } catch (err) {
      console.log(err);
      proxy.$message({
        type: "error",
        message: "取得資料失敗"
      });
    } finally {
      loading.value = false;
    }
  }
  /**
   * 跳轉編輯頁
   */
  function goEdit(id, role) {
    // 到訂單細節頁,將此頁資訊帶入
    const userID = route.params.id;
    const routeName = role !== 0 ? "provider_update" : "member_update";
    const pre = "OrderList";
    router.push({
      name: routeName,
      params: {
        id
      }
    });
  }
  // 點擊重置按鈕事件
  function resetData() {
    filterOpts.value = defaultFilterOpts;
    currentPage.value = 1;
    tableHeadRefDom.value.reset();
    paginationData.value = {
      limit: 10,
      total: 0
    };
    getList();
  }
  // 設定搜尋條件
  function setFilterData() {
    const filter = {};
    for (const key in filterOpts.value) {
      // 判斷 key 是否有值
      if (!isEmpty(filterOpts.value[key])) {
        filter[key] = filterOpts.value[key];
      }
    }
    const result = {
      ...filter,
      page: currentPage.value,
      limit: paginationData.value.limit
    };
    return result;
  }
  // 點擊搜尋按鈕事件
  function filterSearch(val) {
    filterOpts.value = val;
    currentPage.value = 1;
    getList();
  }
  // 更換排序
  const changeSort = val => {
    filterOpts.value.sort_by = val.sortBy;
    filterOpts.value.sort_type = val.orderBy;
    getList();
  };
  onActivated(async () => {
    filterOpts.value = {};
    currentPage.value = 1;
    searchFormRefDom.value.resetData();
    await getList();
  });
  return Object.assign({
    UserPermissions,
    tableHeadRefDom,
    tableLabels,
    searchFormRefDom,
    paginationData,
    currentPage,
    datas,
    pageSizeChange,
    currentPageChange,
    goEdit,
    resetData,
    filterSearch,
    changeSort
  }, {
    setDefault
  });
};
__sfc_main.components = Object.assign({
  SearchForm,
  TableHead,
  MyPagination
}, __sfc_main.components);
export default __sfc_main;
</script>
