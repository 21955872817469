<template>
    <div class="flex p-5 mb-5 bg-white rounded-lg shadow-lg">
        <div class="flex-1 border-r border-gray-300">
            <h5>當月傭金總額</h5>
            <p>{{ data.commission | formatCurrency }}</p>
        </div>
        <div class="flex-1 ml-10 border-r border-gray-300">
            <h5>當月訂單數</h5>
            <p>{{ data.dating_count | formatCurrency }}</p>
        </div>
        <div class="flex-1 ml-10 border-r border-gray-300">
            <h5>當月訂單總額</h5>
            <p>{{ data.dating_price | formatCurrency }}</p>
        </div>
        <div class="flex-1 ml-10">
            <h5>累計傭金</h5>
            <p>{{ data.total_commission | formatCurrency }}</p>
        </div>
    </div>
</template>

<script >
import { ref, reactive, computed, onActivated, onMounted, getCurrentInstance } from "@vue/composition-api";
import { useRoute, useRouter } from "vue2-helpers/vue-router";
import store from "@/store";
// 取得全域 this 方法 ex: this.$api, this.$message
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const {
    proxy
  } = getCurrentInstance();
  const route = useRoute();
  const loading = ref(false);
  const data = ref({
    commission: 0,
    dating_count: 0,
    dating_price: 0,
    total_commission: 0
  });
  /**
   * 取得城市推廣金報表
   */
  async function getReport() {
    loading.value = true;
    try {
      const {
        data: report
      } = await proxy.$api.GetCityPlanCurrentMonthReportApi(route.params.id);
      data.value = report;
    } catch (err) {
      store.dispatch("apiStore/errorCallback", {
        err
      });
    } finally {
      loading.value = false;
    }
  }
  getReport();
  return {
    data
  };
};
export default __sfc_main;
</script>

<style lang="scss" scoped>
h5 {
    @apply font-light;
}
p {
    @apply font-bold text-2xl;
}
</style>
