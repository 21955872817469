<template>
    <div>
        <div class="p-5 bg-white rounded-lg">
            <UnBindSocialMedia
                :haveBindSocialMedia="bindSocialMedias"
                :unBindSocialMedia="unBindSocialMedias"
                @callback="setDefault"
            />
        </div>
        <div class="p-5 mt-10 mb-5 bg-white rounded-lg">
            <div class="flex">
                <h2
                    class="flex-1 mb-3 text-base font-medium md:text-2xl md:mb-10"
                >
                    簡訊通知
                </h2>
                <el-switch
                    v-model="form.openSMS_Notice"
                    active-color="#FF5733"
                    inactive-color="#e5e5e5"
                    :active-value="1"
                    :inactive-value="0"
                    @change="updateSetting"
                >
                </el-switch>
            </div>
            <p class="my-2 text-xs text-gray-300 md:text-base">
                會員向您發起的預訂時，會以傳送簡訊的方式通知您，您無須
                為簡訊負擔額外的費用。
            </p>
        </div>
        <div class="p-5 mt-10 mb-5 bg-white rounded-lg">
            <h2 class="mb-3 text-base font-medium md:text-2xl md:mb-10">
                營業數據
            </h2>
            <div class="flex">
                <div class="flex-1">
                    <div class="mb-2 text-gray-400">預訂回應時間</div>
                    <el-select
                        v-model="providerSalesDataSetting.matrixResponseTime"
                        class="w-full"
                        placeholder="請選擇"
                    >
                        <el-option
                            v-for="item in responseTimeOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </div>
                <div class="flex-1 mx-10">
                    <div class="mb-2 text-gray-400">活動響應率</div>
                    <el-select
                        v-model="providerSalesDataSetting.matrixResponseRate"
                        class="w-full"
                        placeholder="請選擇"
                    >
                        <el-option
                            v-for="item in activityJoinPLrobabilityOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </div>
                <div class="flex-1">
                    <div class="mb-2 text-gray-400">臨時取消</div>
                    <el-input
                        v-model.number="
                            providerSalesDataSetting.matrixNumOfCancel
                        "
                        class="w-full"
                    >
                    </el-input>
                </div>
            </div>
            <div class="flex justify-center my-5">
                <button
                    :disabled="loading"
                    class="bg-red-500 text-white rounded-lg p-2 w-[150px] myDisabled"
                    @click.prevent="updateSetting"
                >
                    儲存
                </button>
            </div>
        </div>
        <div class="p-5 mt-10 mb-5 bg-white rounded-lg">
            <div class="flex">
                <h2
                    class="flex-1 mb-3 text-base font-medium md:text-2xl md:mb-10"
                >
                    快閃活動通知
                </h2>
                <el-switch
                    v-model="form.receiveDemandNotification"
                    active-color="#FF5733"
                    inactive-color="#e5e5e5"
                    :active-value="1"
                    :inactive-value="0"
                    @change="updateSetting"
                >
                </el-switch>
            </div>
            <p class="my-2 text-xs text-gray-300 md:text-base">
                說明 : 當會員向您發起新的即刻快閃時，平台匯集時發送提醒通知您。
            </p>
        </div>
        <div class="p-5 mt-10 mb-5 bg-white rounded-lg">
            <div class="flex">
                <h2
                    class="flex-1 mb-3 text-base font-medium md:text-2xl md:mb-10"
                >
                    CityBananaX 快閃活動通知
                </h2>
                <el-switch
                    v-model="form.disableXService"
                    active-color="#FF5733"
                    inactive-color="#e5e5e5"
                    :active-value="0"
                    :inactive-value="1"
                    @change="updateSetting"
                >
                </el-switch>
            </div>
            <p class="my-2 text-xs text-gray-300 md:text-base">
                說明：CityBananaX 為傳統娛樂活動為主。
            </p>
        </div>
        <div class="p-5 mt-10 mb-5 bg-white rounded-lg">
            <div class="flex">
                <h2
                    class="flex-1 mb-3 text-base font-medium md:text-2xl md:mb-10"
                >
                    CityAI 智能客服
                </h2>
                <el-switch
                    v-model="form.enableCityAi"
                    active-color="#FF5733"
                    inactive-color="#e5e5e5"
                    :active-value="1"
                    :inactive-value="0"
                    @change="setCityAi"
                >
                </el-switch>
            </div>
            <p class="my-2 text-xs text-gray-300 md:text-base">
                為提升用戶體驗及訊息回應效率，當開啟 CityAI 智能客服後，
                機器人將協助您回覆會員，如使用諮詢與回應預訂等問題。 CityAI
                智能客服判定會員為潛在消費者後，會自動通知平台專員
                聯絡您，由您自行決定並回覆是否接受訂單與答覆更詳細的資
                訊。請記得，CityAI 智能客服無法代替您處理任何訂單，且當您
                上線時，CityAI 智能客服將暫停運作。您可隨時啟用或關閉 CityAI
                智能客服功能。
            </p>
        </div>
        <AdvancedInvoice />
    </div>
</template>
<script>
import { mapMutations, mapActions } from "vuex";
// 取消綁定第三方組件
import UnBindSocialMedia from "@/components/UnBindSocialMedia.vue";
// 電子方票設定組件
import AdvancedInvoice from "@/views/user/providers/addProvider/components/advanced/components/AdvancedInvoice.vue";
export default {
    components: {
        UnBindSocialMedia,
        AdvancedInvoice,
    },
    data() {
        return {
            form: {
                openSMS_Notice: 1,
                // 快閃活動通知開關
                receiveDemandNotification: 1,
            },
            // 已綁定第三方帳號資料
            bindSocialMedias: {},
            // 未綁定第三方帳號資料
            unBindSocialMedias: {},
            // 回應效率選項
            responseTimeOptions: [
                {
                    label: "通常很快",
                    value: 1,
                },
                {
                    label: "幾小時內",
                    value: 2,
                },
                {
                    label: "半天內",
                    value: 3,
                },
                {
                    label: "ㄧ天內",
                    value: 4,
                },
                {
                    label: "超過一天",
                    value: 5,
                },
            ],
            // 活動參與率
            activityJoinPLrobabilityOptions: [
                {
                    label: "高",
                    value: 1,
                },
                {
                    label: "普通",
                    value: 2,
                },
                {
                    label: "低",
                    value: 3,
                },
            ],
            // 營業數據設定值
            providerSalesDataSetting: {
                // 回應時間選項
                matrixResponseTime: 2,
                // 活動參與率
                matrixResponseRate: 1,
                // 臨時取消次數
                matrixNumOfCancel: 0,
            },
            loading: false,
        };
    },
    methods: {
        ...mapMutations("providerStore", ["setProviderData"]),
        ...mapActions("apiStore", ["errorCallback"]),
        async setDefault() {
            try {
                const { data } = await this.$api.GetUserDataApi(
                    this.$route.params.id
                );
                const bindSocialMedia = {};
                const unBindSocialMedia = {};
                // 判斷第三方登入使用者是否有資料
                const filterSocialMedias = [
                    "facebook_user",
                    "google_user",
                    "line_user",
                    "apple_user",
                ];
                filterSocialMedias.forEach((socialMedia, index) => {
                    if (!this.$isEmpty(data[socialMedia])) {
                        bindSocialMedia[socialMedia] = data[socialMedia];
                        bindSocialMedia[socialMedia]["index"] = index;
                    }
                });
                filterSocialMedias.forEach((socialMedia, index) => {
                    if (this.$isEmpty(data[socialMedia])) {
                        unBindSocialMedia[socialMedia] = {};
                        unBindSocialMedia[socialMedia]["index"] = index;
                    }
                });
                this.bindSocialMedias = bindSocialMedia;
                this.unBindSocialMedias = unBindSocialMedia;
                this.setProviderData(data);
            } catch (err) {
                console.log(err);
                this.$message({
                    type: "error",
                    message: "取得個人資料失敗",
                });
            }
        },
        // 取得是否收到簡訊通知設定值
        async getSettingsApi() {
            this.loading = true;
            try {
                const {
                    data: {
                        receiveDatingCreatedSMS,
                        enableCityAi,
                        receiveDemandNotification,
                        matrixNumOfCancel,
                        matrixResponseRate,
                        matrixResponseTime,
                        disableXService,
                    },
                } = await this.$api.GetSettingsApi(this.$route.params.id);
                this.$set(
                    this.form,
                    "openSMS_Notice",
                    receiveDatingCreatedSMS === 0 ? 0 : 1
                );
                // 設定 即刻快閃活動開關值
                this.$set(
                    this.form,
                    "receiveDemandNotification",
                    receiveDemandNotification ?? 1
                );
                // 設定 受否接受 Citybanana X 即刻快閃活動開關值
                this.$set(this.form, "disableXService", disableXService ?? 0);
                // 設定 city ai 智能客服開關
                this.$set(this.form, "enableCityAi", enableCityAi ?? 0);
                // 設定 回應時間選項
                this.$set(
                    this.providerSalesDataSetting,
                    "matrixResponseTime",
                    matrixResponseTime ?? 2
                );
                // 設定 活動參與率
                this.$set(
                    this.providerSalesDataSetting,
                    "matrixResponseRate",
                    matrixResponseRate ?? 1
                );
                // 設定 臨時取消次數
                this.$set(
                    this.providerSalesDataSetting,
                    "matrixNumOfCancel",
                    matrixNumOfCancel ?? 0
                );

                await this.setDefault();
            } catch (err) {
                console.log("work advanced =>", err);
                this.$set(
                    this.form,
                    "openSMS_Notice",
                    this.form.openSMS_Notice === 0 ? 0 : 1
                );
                this.errorCallback({ err });
            } finally {
                this.loading = false;
            }
        },
        // 更新設定值
        async updateSetting() {
            try {
                await this.$api.UpdateProfileSetting(this.$route.params.id, {
                    // 簡訊通知開關
                    receiveDatingCreatedSMS: this.form.openSMS_Notice,
                    // 即刻快閃通知開關
                    receiveDemandNotification:
                        this.form.receiveDemandNotification,
                    // Citybanana X 即刻快閃通知開關
                    disableXService: this.form.disableXService,
                    // 回應時間選項
                    matrixResponseTime:
                        this.providerSalesDataSetting.matrixResponseTime,
                    // 活動參與率
                    matrixResponseRate:
                        this.providerSalesDataSetting.matrixResponseRate,
                    // 活動參與率
                    matrixNumOfCancel:
                        this.providerSalesDataSetting.matrixNumOfCancel,
                });
                location.reload();
            } catch (err) {
                this.$set(
                    this.form,
                    "openSMS_Notice",
                    this.form.openSMS_Notice === 1 ? 0 : 1
                );
                this.$set(
                    this.form,
                    "receiveDemandNotification",
                    this.form.receiveDemandNotification === 1 ? 0 : 1
                );
                this.errorCallback({ err });
            }
        },
        // 設定是否啟用 city ai 智能客服
        async setCityAi() {
            try {
                await this.$api.SetCtiyAiApi(
                    this.$route.params.id,
                    this.form.enableCityAi
                );
            } catch (err) {
                // 更新失敗時回覆預設值
                this.$set(
                    this.form,
                    "enableCityAi",
                    this.form.enableCityAi === 1 ? 0 : 1
                );
                this.errorCallback({ err });
            }
        },
    },
    created() {
        this.getSettingsApi();
    },
    activated() {
        this.getSettingsApi();
    },
    // render(h) {
    //     return h("UnBindSocialMedia", {});
    // }
};
</script>
