<template>
    <div class="p-5 mt-10 mb-5 bg-white rounded-lg">
        <h2 class="mb-3 text-sm font-medium md:mb-10 md:text-2xl">
            電子發票設定
        </h2>

        <div v-for="(type, key) in invoiceTypes" :key="type.value" class="mb-2">
            <el-radio
                v-model="form.invoice.carrier_type"
                class="block mb-2"
                :label="type.value"
                @change="onChnageRadio"
                >{{ type.label }}</el-radio
            >
            <div
                v-show="
                    key !== 'love_code' &&
                    form.invoice.carrier_type === type.value &&
                    type.value !== 3
                "
            >
                <el-input
                    v-model="form.invoice[key]"
                    class="mr-2"
                    :name="key"
                    :placeholder="type.placeholder"
                    @blur="onBlur"
                ></el-input>
                <button
                    class="rounded border border-orange-600 px-3 py-0.5 text-sm text-orange-600 disabled:cursor-not-allowed disabled:bg-gray-300"
                    @click.prevent="
                        updateSetting(route.params.id, form.invoice)
                    "
                >
                      確認
                </button>
            </div>
            <div
                v-show="
                    form.invoice.carrier_type === type.value && type.value === 3
                "
            >
                <el-select
                    v-model="form.invoice[key]"
                    class="mr-2"
                    :placeholder="type.placeholder"
                >
                    <el-option
                        v-for="item in love_codes"
                        :key="item.code"
                        :label="item.name"
                        :value="item.code"
                    ></el-option>
                </el-select>
                <button
                    class="rounded border border-orange-600 px-3 py-0.5 text-sm text-orange-600 disabled:cursor-not-allowed disabled:bg-gray-300"
                    @click.prevent="
                        updateSetting(route.params.id, form.invoice)
                    "
                >
                      確認
                </button>
            </div>
            <div
                v-if="errorMessage && form.invoice.carrier_type === type.value"
                class="text-red-500"
            >
                {{ errorMessage }}
            </div>
        </div>
    </div>
</template>

<script >
import { ref, reactive, computed, onActivated, onMounted, watch, getCurrentInstance, defineExpose, onBeforeUnmount, nextTick } from "@vue/composition-api";
import { useRoute, useRouter } from "vue2-helpers/vue-router";
import { isEmpty } from "@/service/anyService";
import { Message } from "element-ui";
const __sfc_main = {};
__sfc_main.props = {
  invoice: {
    type: Object,
    default() {
      return {
        love_code: "",
        carrier_num: "",
        carrier_type: 1,
        carrier_email: ""
      };
    }
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const route = useRoute();
  const {
    proxy
  } = getCurrentInstance();
  const props = __props;
  const form = ref({
    invoice: props.invoice
  });
  const invoiceTypes = ref({
    carrier_email: {
      label: "電子信箱",
      value: 2,
      placeholder: "請輸入E-mail"
    },
    carrier_num: {
      label: "存入載具",
      value: 1,
      placeholder: "/"
    },
    love_code: {
      label: "捐贈",
      value: 3,
      placeholder: "請選擇捐贈單位"
    }
  });

  // 捐贈碼
  const love_codes = ref([]);
  const errorMessage = ref(null);
  async function getLoveCodes() {
    try {
      const {
        data
      } = await proxy.$api.GetLoveCodesApi();
      love_codes.value = data.love_codes;
    } catch (err) {
      Message({
        type: "error",
        message: "取得捐贈碼失敗"
      });
    }
  }

  /**
   * 驗證信箱格式
   * @param { type String(字串) } email 信箱
   */
  async function checkEmail(email) {
    if (/^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/.test(email)) {
      errorMessage.value = null;
      return true;
    }
    errorMessage.value = "信箱驗證失敗";
    if (!isEmpty(email) && !/^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/.test(email)) {
      await getSettingsByKey(route.params.id, "carrier_email");
    }
    return false;
  }

  /**
   * 驗證手機載具
   * @param { type String(字串) } vehicle 載具
   */
  async function checkVehicle(vehicle) {
    if (/^\/[0-9A-Z.+-]{7}$/.test(vehicle)) {
      errorMessage.value = null;
      return true;
    }
    errorMessage.value = "載具驗證失敗";
    if (!isEmpty(vehicle) && !/^\/[0-9A-Z.+-]{7}$/.test(vehicle)) {
      await getSettingsByKey(route.params.id, "carrier_num");
    }
    return false;
  }
  async function onBlur(val) {
    // // 驗證信箱格式
    // if (form.value.invoice.carrier_type === 2) {
    //     if (checkEmail(form.value.invoice[val.target.name])) {
    //         await updateSetting(route.params.id, form.value.invoice);
    //     }
    // }
    // // 驗證手機載具
    // if (form.value.invoice.carrier_type === 1) {
    //     if (checkVehicle(form.value.invoice[val.target.name])) {
    //         await updateSetting(route.params.id, form.value.invoice);
    //     }
    // }
  }

  /**
   * 選擇電子發票開立方式
   * @param { type Number(數字) } val 選擇值
   */
  async function onChnageRadio(val) {
    // 驗證信箱格式
    if (val === 2) {
      if (!checkEmail(form.value.invoice["carrier_email"])) {
        form.value.invoice["carrier_email"] = null;
      }
    }
    // 驗證手機載具
    if (val === 1) {
      if (!checkVehicle(form.value.invoice["carrier_num"])) {
        form.value.invoice["carrier_num"] = null;
      }
    }
    // // 驗證是否選擇捐贈單位
    // if (
    //     form.value.invoice.carrier_type === 3 &&
    //     !isEmpty(form.value.invoice["love_code"])
    // ) {
    //     await updateSetting(route.params.id, form.value.invoice);
    // }
    errorMessage.value = null;
  }

  /**
   * 取得使用者電子發票格式設定值
   * @param { type String(字串) } userId 使用者 id
   *"invoice": {
        "love_code": "123",
        "carrier_num": "/5-+658Q",
        "carrier_type": 2, //發票種類 1.手機條碼載具 2.email 3.捐贈
        "carrier_email": "a12345@gmail.com"
      }
   */
  async function getSettings(userId) {
    try {
      const {
        data
      } = await proxy.$api.GetSettingsApi(userId);
      if (data.invoice) {
        form.value.invoice = data.invoice;
      } else {
        form.value.invoice = {
          love_code: "",
          carrier_num: "",
          carrier_type: 1,
          carrier_email: ""
        };
      }
    } catch (err) {
      console.log("GetSettingsApi err =>", err);
    }
  }

  /**
   * 取得設定值 但只更新單一選項值
   * 用來離開輸入框後取回預設值
   * @param { type String(字串) } userId 使用者 id
   * @param { type String } key 更新 key
   */
  async function getSettingsByKey(userId, key) {
    try {
      const {
        data
      } = await $api.GetSettingsApi();
      if (data.invoice) {
        form.value.invoice[key] = data.invoice[key];
      } else {
        form.value.invoice[key] = null;
      }
    } catch (err) {
      console.log("GetSettingsApi err =>", err);
    }
  }

  /**
   * 更新電子發票設定值
   * @param { type String(字串) } userId 使用者 id
   * @param { type Object(物件) } invoice 電子發票格式
   * @example {
      {
          "love_code": "123",
          "carrier_num": "/5-+658Q",
          "carrier_type": 2, //發票種類 1.手機條碼載具 2.email 3.捐贈
          "carrier_email": "a12345@gmail.com"
         }
      * }
   */
  async function updateSetting(userId, invoice) {
    const request = invoice;
    if (invoice.carrier_type === 2) {
      if (!checkEmail(invoice["carrier_email"])) {
        await getSettings(route.params.id);
        return;
      }
    }
    if (invoice.carrier_type === 1) {
      if (!checkVehicle(invoice["carrier_num"])) {
        await getSettings(route.params.id);
        return;
      }
    }
    if (invoice.carrier_type === 3 && isEmpty(invoice.love_code)) {
      errorMessage.value = "請選擇捐贈單位";
      return;
    }
    errorMessage.value = null;
    try {
      await proxy.$api.SetInvoiceApi(userId, request);
      await getSettings(userId);
    } catch (err) {
      console.log("SetInvoiceApi err =>", err);
      Message({
        type: "error",
        message: "更新電子發票格式失敗"
      });
    }
  }
  onMounted(async () => {
    await getSettings(route.params.id);
    await getLoveCodes();
  });
  onActivated(async () => {
    await getSettings(route.params.id);
  });
  return {
    route,
    form,
    invoiceTypes,
    love_codes,
    errorMessage,
    onBlur,
    onChnageRadio,
    updateSetting
  };
};
export default __sfc_main;
</script>

<style lang="scss" scoped>
::v-deep {
    .el-input {
        width: 300px;
    }
    .el-select {
        width: 300px;
    }
    .el-radio {
        @apply block;
    }
    .el-radio__input.is-checked .el-radio__inner {
        width: 20px;
        height: 20px;
        @apply bg-white border-red-500;
    }
    .el-radio__inner {
        width: 20px;
        height: 20px;
    }
    .el-radio__input.is-checked .el-radio__inner::after {
        width: 10px;
        height: 10px;
        @apply bg-red-500;
    }
    .el-radio__input.is-checked + .el-radio__label {
        @apply text-black;
    }
}
</style>
