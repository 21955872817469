<script>
import {
    ref,
    reactive,
    del,
    set,
    computed,
    onActivated,
    onMounted,
    watch,
    getCurrentInstance,
    defineExpose,
    onBeforeUnmount,
    nextTick,
} from "@vue/composition-api";
// 分頁組件
import MyPagination from "@/components/MyPagination.vue";
// 旋轉動畫組件
import LoadingComponent from "@/components/Loading.vue";
// 訂單相關 configs
import orderConfig from "@/config/orderConfig.js";
// table Head
import TableHead from "@/components/table/TableHead.vue";
import store from "@/store";
import { useRoute, useRouter } from "vue2-helpers/vue-router";
import { isEmpty, formatCurrency, formatDateTime } from "@/service/anyService";

export default {
    name: "UserOrderList",
    components: {
        LoadingComponent,
        MyPagination,
        TableHead,
    },
    props: {
        // 當前 user 是否為服務商
        isProvider: {
            type: Boolean,
            default: true,
        },
    },
    emits: [],
    setup(props, { emit }) {
        const { proxy } = getCurrentInstance();
        const route = useRoute();
        const router = useRouter();
        const loading = ref(false);

        // 時間範圍
        const dateInputs = ref([]);

        // 搜尋條件表單資料
        const form = ref({});

        // 顯示金額需額外加上需約單統計
        function getPriceByItem(info) {
            let price = 0;
            let fee = 0;
            let price2 = 0;
            let fee2 = 0;
            info.dating_extensions.forEach((i) => {
                // 只取完成的續約單統計
                if (i.status === 2) {
                    price += i.details.price;
                    fee += i.details.fee;
                }
                // 未確認
                if (i.status === 1) {
                    price2 += i.details.price;
                    fee2 += i.details.fee;
                }
            });

            const totalCount =
                price + price2 + info.details.tip + info.details.serviceCharge;
            const serviceCharge = info.details.fee + fee + fee2;

            return {
                price: totalCount + serviceCharge,
            };
        }

        // 過濾條件
        const filterData = computed(() => {
            Object.keys(form.value).forEach((key) => {
                if (isEmpty(form.value[key])) {
                    del(form.value, key);
                }
            });
            console.log("form.value => ", form.value, currentPage.value);
            return {
                ...form.value,
                role: props.isProvider ? 1 : 0,
                limit: paginationData.value.limit,
            };
        });

        // 訂單狀態 options
        const statusOptions = computed(() => {
            const status = orderConfig.orderStatus;
            const result = [
                {
                    label: "全部",
                    value: "",
                },
            ];
            for (const key in status) {
                result.push({
                    label: status[key],
                    value: key,
                });
            }
            return result;
        });

        // 資料
        const datas = ref([]);

        const tableLabels = ref([
            {
                label: "狀態",
            },
            {
                label: props.isProvider ? "會員暱稱" : "服務商暱稱",
            },
            {
                label: "活動項目",
            },
            {
                label: "訂單總額",
                isSort: true,
                val: "gross_price",
            },
            {
                label: "付款類型",
            },
            {
                label: "成立時間",
                isSort: true,
                val: "created_at",
            },
            {
                label: "開始時間",
                isSort: true,
                val: "started_at",
            },
            {
                label: "查看",
            },
        ]);

        // 分頁資料
        const paginationData = ref({
            limit: 10,
            total: 0,
        });

        // 當前頁數
        const currentPage = ref(1);

        /**
         * 透過父組件 呼叫執行
         * @param { type Object(物件) } userData 使用者資料
         */
        function setDefault(userData) {
            getList({
                userID: userData.id,
                filter: filterData.value,
                page: currentPage.value,
            });
        }

        // 顯示一頁幾筆資料切換
        function pageSizeChange(val) {
            // 更新每頁取得幾筆資料參數
            paginationData.value.limit = val;
            // 當前頁面設定為第一頁
            currentPage.value = 1;
            getList({
                userID: route.params.id,
                filter: filterData.value,
                page: currentPage.value,
            });
        }

        // 分頁切換
        function currentPageChange(val) {
            // 設定目前分頁為第幾面
            currentPage.value = val;
            getList({
                userID: route.params.id,
                filter: filterData.value,
                page: currentPage.value,
            });
        }

        /**
         * 改變排序
         * @param { type Object(物件) } sortData
         * @example {
         *   @param { type String(字串) } sortBy 要排序的  key
         *   @param { type String(字串) } orderBy 排序 正序 或 倒序  enum ["desc", "asc"]
         * }
         */
        function changeSort(sortData) {
            currentPage.value = 1;
            form.value.sort_by = sortData.sortBy;
            form.value.sort_type = sortData.orderBy;
            getList({
                userID: route.params.id,
                filter: filterData.value,
                page: currentPage.value,
            });
        }

        function reset() {
            form.value = {};
            currentPage.value = 1;
            paginationData.value = {
                limit: 10,
                total: 0,
            };
            dateInputs.value = [];
            if (this.$refs.tableHeadRefDom) {
                this.$refs.tableHeadRefDom.reset();
            }
            getList({
                userID: route.params.id,
                filter: filterData.value,
                page: currentPage.value,
            });
        }

        /**
         * 跳轉訂單細節頁
         * @param { type String(字串) } orderID 訂單 id
         * @param { type String(字串) } userID 使用者 id
         */
        function goEdit(orderID, userID) {
            // 路由權限 key
            const pre = "UserOrderList";
            const tab = props.isProvider ? "provider_update" : "member_update";
            router.push({
                name: "order_detail",
                params: { tab, pre, orderID, userID },
            });
        }

        /**
         * 取得資料
         * @param { type String(字串) } userID 使用者 id
         * @param { type Object(物件) } filter 過濾參數
         */
        async function getList({ userID, filter = {}, page = 1 }) {
            loading.value = true;
            try {
                const { data } = await proxy.$api.SearchDatings(userID, {
                    ...filter,
                    page,
                });
                datas.value = data.data;
                paginationData.value = {
                    limit: data.per_page,
                    total: data.total,
                };
            } catch (err) {
                proxy.$message({
                    type: "error",
                    message: "取得資料失敗",
                });
            } finally {
                loading.value = false;
            }
        }

        /**
         * 搜尋條件
         * @param { type String(字串) } userID 使用者 id
         * @param { type Object(物件) } filter 過濾參數
         */
        async function search({ userID, filter = {} }) {
            currentPage.value = 1;
            getList({
                userID,
                filter,
                page: currentPage.value,
            });
        }

        watch(
            () => dateInputs.value,
            (val) => {
                if (val === null || val.length === 0) {
                    del(form.value, "started_at_start");
                    del(form.value, "started_at_end");
                    return;
                }
                form.value.started_at_start = val[0];
                form.value.started_at_end = val[1];
            }
        );

        // onActivated(async () => {
        //     await getList({
        //         userID: route.params.id,
        //         filter: filterData.value,
        //     });
        // });
        return {
            setDefault,
            currentPage,
            paginationData,
            pageSizeChange,
            currentPageChange,
            loading,
            form,
            statusOptions,
            filterData,
            tableLabels,
            changeSort,
            datas,
            goEdit,
            getList,
            search,
            currentPage,
            reset,
            dateInputs,
            getPriceByItem,
            route,
        };
    },
    render() {
        const searchForm = (
            <div class="flex items-end p-5 mb-3 bg-white rounded-lg shadow-lg">
                <div class="mr-5 w-[150px]">
                    <label class="text-gray-400">
                        訂單編號
                        <el-input
                            vModel={this.form.order_id}
                            size="small"
                            clearable
                        ></el-input>
                    </label>
                </div>
                <div class="flex items-end mr-5">
                    <div class="flex-1 block text-gray-400">
                        <label class="block">開始日期 範圍</label>
                        <el-date-picker
                            vModel={this.dateInputs}
                            size="small"
                            type="daterange"
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="開始日期"
                            class="max-w-[300px]"
                            end-placeholder="结束日期"
                        ></el-date-picker>
                    </div>
                </div>
                <div class="mr-5">
                    <div class="block text-gray-400">狀態</div>

                    <el-select
                        vModel={this.form.status}
                        size="small"
                        class="max-w-[150px]"
                        clearable
                    >
                        {this.statusOptions.map((option, index) => (
                            <el-option
                                key={index}
                                label={option.label}
                                value={option.value}
                            >
                                {option.label}
                            </el-option>
                        ))}
                    </el-select>
                </div>
                <div class="flex-1 text-right">
                    <button
                        class="orange-btn-800 md:text-sm text-xs mr-1 w-[73px] h-[30px] flex-1"
                        vOn:click_prevent={async () =>
                            await this.search({
                                userID: this.route.params.id,
                                filter: this.filterData,
                            })
                        }
                    >
                        搜尋
                    </button>
                    <button
                        class="black-btn-outline md:text-sm text-xs w-[73px] h-[30px] flex-1"
                        vOn:click_prevent={() => this.reset()}
                    >
                        重置
                    </button>
                </div>
            </div>
        );

        const tableBody = (
            <tbody>
                {this.datas.map((data, index) => {
                    return (
                        <tr
                            key={data.id}
                            class="text-center transition duration-200"
                        >
                            <td>
                                {orderConfig.orderStatus[data.status] ??
                                    "未知狀態"}
                            </td>
                            <td>
                                {this.isProvider
                                    ? data.user.name
                                    : data.provider.name}
                            </td>
                            <td>{data.category.name}</td>
                            <td>
                                $
                                {formatCurrency(
                                    this.getPriceByItem(data).price
                                )}
                            </td>
                            <td>{data.paid_by === 1 ? "現金" : "線上"}</td>
                            <td>{formatDateTime(data.created_at)}</td>
                            <td>{formatDateTime(data.started_at)}</td>
                            <td
                                class="cursor-pointer"
                                vOn:click={() =>
                                    this.goEdit(data.id, this.route.params.id)
                                }
                            >
                                <i class="far fa-edit"></i>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        );

        return (
            <div>
                <LoadingComponent isLoading={this.loading} />
                {searchForm}
                <div class="py-5 bg-white">
                    <div class="overflow-x-auto bg-white">
                        <table class="myTable">
                            <TableHead
                                ref="tableHeadRefDom"
                                labels={this.tableLabels}
                                vOn:changeSort={this.changeSort}
                            />
                            {tableBody}
                        </table>
                    </div>
                    <MyPagination
                        class="mt-5"
                        paginationData={this.paginationData}
                        currentPage={this.currentPage}
                        vOn:onPageSizeChange={this.pageSizeChange}
                        vOn:onCurrentPageChange={this.currentPageChange}
                    />
                </div>
            </div>
        );
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .el-range-editor.el-input__inner {
        @apply w-full;
    }
}
</style>
