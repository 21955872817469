// 訂單狀態
const verificationType = {
    0: "待審核",
    1: "審核通過",
    "-1": "未通過",
};

export const UserPermissions = {
    0: "使用中",
    1: "使用中",
    2: "使用中",
    "-1": "停權",
    "-2": "永久停權",
};

export default {
    verificationType,
};
